import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

function About(props) {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = props.title

    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/about',
        pageTitle: props.title,
      },
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="About-section align-items-center">
      <div className="text-center">
        <h6 className="darkBlueHighlight">{t("AboutUs.AboutTheCompany")}</h6>
        <h1 className="halfWidth mx-auto">
         {t('AboutUs.SubHeader')}
        </h1>
        <div className="videoContainer mt-5 p-2">
          <video width="80%" height="80%" controls autoPlay>
            <source
              src="https://falconwatertech.com/Falconhistory.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div className="container-fluid px-3 px-md-5 py-5">
        <h2 className="text-center">{t("AboutUs.ValuesHeader")}</h2>
        <div className="row mt-5">
          <div className="col-12 col-md-4 mb-4">
            <div className="card h-100">
              <div className="cardHeaderImage">
                <img
                  src="./assets/images/icons/ecology 1.png"
                  className="img-fluid"
                  alt="no image"
                />
              </div>

              <div className="card-body text-center">
                <h6 style={{textAlign:'center'}} className="card-title">{t("AboutUs.EcoFriendlyProductsTitle")}</h6>
                <p className="card-text text-center">
                 {t("AboutUs.EcoFriendlyProductsBody")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mb-4">
            <div className="card h-100">
              <div className="cardHeaderImage">
                <img
                  src="./assets/images/icons/piggy-bank 1.png"
                  className="img-fluid"
                  alt="no image"
                />
              </div>

              <div className="card-body">
                <h6 style={{textAlign:'center'}}  className="card-title">{t("AboutUs.PocketFriendlyServicesTitle")}</h6>
                <p className="card-text text-center">
                {t("AboutUs.PocketFriendlyServicesBody")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mb-4">
            <div className="card h-100">
              <div className="cardHeaderImage">
                <img
                  src="./assets/images/icons/satisfaction 1.png"
                  className="img-fluid"
                  alt="no image"
                />
              </div>

              <div className="card-body">
                <h6 style={{textAlign:'center'}} className="card-title">{t("AboutUs.CustomerSatisfactionTitle")}</h6>
                <p className="card-text text-center">
                {t("AboutUs.CustomerSatisfactionBody")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-3 px-md-5 py-5">
        <div className="row ourCompanyContainer">
          <div className="col-12 col-md-6 pt-md-5" style={{alignItems:'center'}}>
            <h2 style={{textAlign:'center'}}>{t("AboutUs.OurCompanyTitle")}</h2>
            <p className="mt-4" style={{textAlign:'center',margin: 'auto'}}>
            {t("AboutUs.OurCompanyBody")}
            </p>
            <div className="d-flex mt-3 w-75" style={{
              margin: 'auto',
              justifyContent:'center',
              backgroundColor: 'aliceblue'}}>
              <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <h5 style={{textAlign:'center'}}>14</h5>
                <h6 style={{textAlign:'center'}}>{t("AboutUs.YearsSuccessIsrael")}</h6>
              </div>
              <div className="darkBlueHighlight" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <h5 style={{textAlign:'center'}}>35</h5>
                <h6 style={{textAlign:'center'}}>{t("AboutUs.YearsSuccessWorld")}</h6>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="ImageContainer">
              <img
                src="./assets/images/Rectangle 29.png"
                alt="no image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid px-3 px-md-5">
        <div className="sponsererContainer py-5">
          <div className="row">
            <h3 className="text-center mb-5">{t("AboutUs.OurPartners")}</h3>
            <div className="col-6 col-md-2 text-center mb-4 ms-md-5">
              <div className="sponserer">
                <img
                  src="./assets/images/1027442.png"
                  alt="no image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-6 col-md-2 text-center mb-4">
              <div className="sponserer">
                <img
                  src="./assets/images/1028945 1.png"
                  alt="no image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-6 col-md-2 text-center mb-4">
              <div className="sponserer">
                <img
                  src="./assets/images/1028942.png"
                  alt="no image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-6 col-md-2 text-center mb-4">
              <div className="sponserer">
                <img
                  src="./assets/images/1028928.png"
                  alt="no image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-2 text-center mb-4">
              <div className="sponserer">
                <img
                  src="./assets/images/1028941.png"
                  alt="no image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
