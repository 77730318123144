import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Articles from './Articles/Articles';
import Article from './Articles/Article';
import Contact from '../components/Contact';
import About from '../components/About';
import Home from '../components/Home/Home';

const Router = () => {
    debugger
  return (
    <Routes>
      <Route path="/" element={<Home title="משתנות ללא מים ווטרלס | דף הבית" />} />
      <Route path="/articles" element={<Articles title="משתנות ללא מים ווטרלס | מאמרים" />} />
      <Route path="/article/:route" element={<Article />} />
      <Route path="/about" element={<About title="משתנות ללא מים ווטרלס | אודות" />} />
      <Route path="/contact" element={<Contact title="משתנות ללא מים ווטרלס | צור קשר" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
