import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import articles from "../../backend/db/articles.json";

const ArticleCard = ({ title, benefits, conclusion }) => (
  <div className="card shadow-sm">
    <div className="card-body">
      <h3 className="card-title text-center mb-4">{title}</h3>
      <ul className="list-group list-group-flush">
        {benefits && benefits.map((benefit, index) => (
          <BenefitItem key={index} title={benefit.Title} description={benefit.Description} />
        ))}
      </ul>
      <div style={{
        borderTop: "1px solid #dee2e6",
        marginTop: "20px",
        paddingTop: "20px"
      }}>
      <p className="card-text text-end" style={{
        width: "100%"
      }}>{conclusion}</p>
      </div>
    </div>
  </div>
);

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.shape({
    Title: PropTypes.string,
    Description: PropTypes.string,
  })),
  conclusion: PropTypes.string.isRequired,
};

const BenefitItem = ({ title, description }) => (
  <li className="list-group-item text-end"><span className="fw-bold text-end">{title}:</span> {description}</li>
);

BenefitItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const Article = () => {
  const { route } = useParams();
  const article = articles.find((article) => article.Route === route);

  if (!article) {
    return <div className="alert alert-warning" role="alert">Article not found</div>;
  }

  return (
    <div className="container mt-5" style={{
      marginBottom: "100px"
    }}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <ArticleCard title={article.Introduction} benefits={article.Benefits} conclusion={article.Conclusion} />
        </div>
      </div>
    </div>
  );
};

export default Article;
