import React from "react";
import { Link } from "react-router-dom";

const ArticleRouter = ({ title, route, introduction }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h3 className="card-title text-center">{title}</h3>
        <p className="card-text text-end" style={{ width: '100%' }}>
          {introduction}
        </p>
        <Link
          to={`/article/${route}`}
          className="btn btn-primary"
          style={{
            backgroundColor: '#5bbaca',
            borderColor: '#5bbaca',
            color: '#ffffff',
          }}
        >
          קרא עוד
        </Link>
      </div>
    </div>
  );
};

export default ArticleRouter;
