import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {useDirection} from "../../useDirection";
import TagManager from "react-gtm-module";
import EmailForm from '../EmailForm/EmailForm';
import CustomerReviews from '../CustomerReviews';
import ContainerHomeForm from '../EmailForm/ContainerHomeForm/ContainerHomeForm';

function Home(props) {
  const [t,i18n,isRTL] = useDirection('rtl')

  useEffect(() => {
    document.title = props.title

    window.scrollTo(0, 0);

    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/home',
        pageTitle: props.title,
      },
    });
  }, [])


  return (
    <div className="Home-section">
      <div className="container-fluid px-3 px-md-5">
        <div className={"row"} style={{flexDirection: isRTL ? 'row-reverse' : 'row'}}>
          <div className="col-12 col-md-6" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h1 style={{textAlign: 'center'}}>
              <div>{t('Home.Header_1')}
                <span className="greenHighlight"> {t('Home.Header_2')}</span></div>
              <span>{t('Home.Header_3')}</span>
            </h1>
            <h3 style={{textAlign: 'center', color:'#24af94'}}>
              <div>{t('Home.Falcon_4')}</div>
            </h3>
            <div className={'d-none d-md-block'} style={{textAlign: 'center',fontSize:'20px', width:'70%',marginTop:'30px'}}>{t('Home.SubHeader')}</div>
          </div>
          <div className="col-12 col-md-6 water-system-Image-container mt-5 mt-md-0">
            <div className="water-system-Image d-none d-lg-flex">
              <img
                  src="./assets/images/Home1.png"
                  alt="no image"
                  className="img-fluid"
              />
            </div>

            <div className="water-system-Image d-none">
              <img
                  src="./assets/images/Mobile.png"
                  alt="no image"
                  className="img-fluid"
              />
            </div>

          </div>
        </div>
      </div>
      <div className="container-fluid px-3 px-md-5" style={{marginTop: '40px'}}>
        <div className={"row align-items-center"} style={{flexDirection: isRTL ? 'row-reverse' : 'row'}}>
          <div className="col-12 col-md-5 align-items-sm-center" style={{display: 'flex', flexDirection: 'column',}}>
            <h2 style={{textAlign:'center'}}  className={'align-items-sm-center align-items-md-end'} >המוצרים שלנו</h2>
            <p style={{textAlign: 'center',fontSize:'20px'}}>{t('Home.Healthy')}</p>

          </div>
          <div className="col-12 col-md-7 productCarouselContainer mt-5 mt-md-0">

            <Carousel className="d-lg-none"
                      infiniteLoop={true}
                      autoPlay={true}
                      showStatus={false}
                      showArrows={false}
                      showThumbs={false}
                      interval={5000}
            >
              <div class="item ">
                <div class="card">
                  <img
                      src="./assets/images/Falcon Velocity.png"
                      alt="no image"
                      class="img-fluid img-one"
                  />
                  <div class="hover-content">
                    <h3 class={'text-white'} style={{marginTop:'auto'}}>
                      פילטר מונע ריחות והתזות מבית Falcon Velocity™
                    </h3>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card">
                  <img
                      src="./assets/images/urinal-1 1.png"
                      alt="no image"
                      class="img-fluid img-two"
                  />
                  <div class="hover-content">
                    <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™

                    </h3>
                  </div>
                </div>
              </div>


              <div class="item ">
                <div class="card">
                  <img
                      src="./assets/images/urinal-1 2.png"
                      alt="no image"
                      class="img-fluid img-three"
                  />
                  <div class="hover-content">
                    <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™

                    </h3>
                  </div>
                </div>
              </div>

            </Carousel>

            <Carousel className="d-none d-lg-flex"
                      infiniteLoop={true}
                      autoPlay={true}
                      showStatus={false}
                      showArrows={false}
                      showThumbs={false}
                      interval={5000}
            >

              <div class="row ">
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/Falcon Velocity.png"
                          alt="no image"
                          class="img-fluid img-one"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop: 'auto'}}>
                          פילטר מונע ריחות והתזות מבית Falcon Velocity™
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/urinal-1 1.png"
                          alt="no image"
                          class="img-fluid img-two"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/urinal-1 2.png"
                          alt="no image"
                          class="img-fluid img-three"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™

                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row ">
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/Falcon Velocity.png"
                          alt="no image"
                          class="img-fluid img-one"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop:'auto'}}>
                          פילטר מונע ריחות והתזות מבית Falcon Velocity™
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/urinal-1 1.png"
                          alt="no image"
                          class="img-fluid img-two"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™

                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="item">
                    <div class="card">
                      <img
                          src="./assets/images/urinal-1 2.png"
                          alt="no image"
                          class="img-fluid img-three"
                      />
                      <div class="hover-content">
                        <h3 class={'text-white'} style={{marginTop: 'auto'}}>משתנות ללא מים מבית Falcon Velocity™

                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>


          </div>
        </div>
      </div>
      <div className="container-fluid px-3 px-md-5 lightGreen">
        <div className="row mt-5 py-5">
          <h2 className="CustomWidth text-center mb-5">
            {t('Home.Designed To Provide')}
            <span className="blueHighlight"> {t('Home.More than Just')}</span>
            {t('Home.Hygeine')}
          </h2>


          <Carousel
              infiniteLoop={true}
              autoPlay={false}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              interval={1500}
          >
            <div>
              <div>
                <div className="item">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="HygieneImage">
                        <img
                            src="./assets/images/Group 97.png"
                            alt="no image"
                            className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 pt-5" style={{textAlign: '-webkit-right'}}>
                      <div>
                        <div>
                          <div style={{display: 'flex', justifyContent: 'end'}}><h3>ללא מים</h3><h3>.1</h3></div>
                          <p style={{textAlign: 'end'}}>
                          הטכנולוגיה מבטיחה חיסכון של מאות אלפי ליטרים של מים ואלפי שקלים בשנה. כלומר, זה שווה לכמות המים שיכולה להתקבל במסגרת כמה סכרים.
                          </p>
                        </div>
                        <hr/>
                        <div>
                          {/*<h3>2 . Repellent Clay Body</h3>*/}
                          <div style={{display: 'flex', justifyContent: 'end'}}><h3>משתנה מגוף חרס</h3><h3>.2</h3></div>

                          <p style={{textAlign: 'end'}}>
                          .המשתנות מורכבות מחומר חרס דוחה נוזלים, המונע הידבקות של שתן, ומצוידות בסיפון המונע ריחות נוזלים ומותפתות ביכולת לסנן אבנית
                          </p>
                        </div>
                        <hr/>
                        <div>
                          {/*<h3>3 . No Electricity</h3>*/}
                          <div style={{display: 'flex', justifyContent: 'end'}}><h3>ללא חשמל</h3><h3>.3</h3></div>

                          <p style={{textAlign: 'end'}}>
                          .המשתנות שלנו פועלות לחלוטין ללא צורך במתג חשמלי, תקע חשמל, סוללה או כל מקור אנרגיה חיצוני נוסף. הן מפעילות את עצמן באמצעות מנגנון מתקדם ביותר ועיצוב יעיל
                          </p>
                        </div>
                        <hr/>
                        <div>
                          {/*<h3 style={{textAlign:'end'}}>4 . Scale Capturing Filter</h3>*/}
                          <div style={{display: 'flex', justifyContent: 'end'}}><h3>מסנן מונע סתימות</h3><h3>.4</h3>
                          </div>

                          <p style={{textAlign: 'end'}}>
                            סתימות נפוצות בשירותים, מכיוון שיש אבנית בשתן.
                            המסנן אוסף את רוב האבנית מהשתן וכתוצאה מכך מונע סתימות בשירותים.
                          </p>
                        </div>
                        <hr/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Carousel>


        </div>
      </div>

      <CustomerReviews/>
      <ContainerHomeForm/>

    </div>

  );
}


export default Home;
