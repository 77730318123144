import React, { useEffect, useState } from "react";
import {useDirection} from "../useDirection";
import emailjs from "@emailjs/browser";
import TagManager from "react-gtm-module";

function Contact(props) {
  const [t,i18n,isRTL] = useDirection('rtl')
  const [formData, setFormData] = useState({
    from_name: "",
    email_address: "",
    phone_number:'',
    message: "",
  });

  useEffect(() => {
    document.title = props.title


    window.scrollTo(0, 0);
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/contact',
        pageTitle: props.title,
      },
    });
  }, []);


  const sendEmail = () => {

    emailjs.send('service_x8pw3kx', 'template_jkunoy7', {...formData})
    .then(function(response) {

      TagManager.dataLayer({
        dataLayer: {
          event: 'contact_us',
          pageTitle: document.title,
        },
      });

      setFormData({
        from_name: '',
        email_address: '',
        phone_number: '',
        message:'',
      })

   }, function(error) {
      console.log('FAILED...', error);
   });

  }


  // function sendEmail() {
  //   const {name, email, subject, message} = formData;
  //   window.Email.send({
  //     Host: "smtp.elasticemail.com",
  //     Username: MAIL_FROM,
  //     Password: ELASTICMAIL_PASSWORD,
  //     To: MAIL_TO,
  //     From: MAIL_FROM,
  //     Subject: subject,
  //     Body: `Name: ${name} <br /> Email: ${email} <br /> Message: ${message}`,
  //   }).then((message) => alert(message)).catch(()=>alert('error'));
  // }
  return (
    <div className="Contact-section">
      <div className="text-center">
        <h1 className="halfWidth mx-auto">
          {t('Contact.Header')}
        </h1>

      </div>
      <div className="container-fluid px-3 px-md-5 py-5">
        <div className="getInTouchContainer mx-auto">
          <h2 style={{textAlign:isRTL? 'end' : 'start'}} className="darkPurpleHighlight">{t('Contact.Contact')}</h2>
          <div className={'row'} style={{display:'flex',flexDirection: isRTL ?'row-reverse' :'row'}}>
            <div className="col-12 col-md-5">
              <div className="contactForm">
                <div className="d-lg-flex align-items-center">
                  <div className="form-input d-flex nameInput"
                       style={{flexDirection: isRTL ?'row-reverse' :'row',padding:'5px'}}>
                    <span className="inputLabel">{t('Contact.Form.Name')}</span>
                    <input required
                        style={{textAlign:isRTL? 'end': 'start'}}

                        type="text"
                      value={formData.from_name}
                      onChange={(e) =>
                        setFormData({ ...formData, from_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-input d-flex" style={{flexDirection: isRTL ?'row-reverse' :'row',padding:'5px'}}>
                    <span className="inputLabel">{t('Contact.Form.Email')}</span>
                    <input required
                        style={{textAlign:isRTL? 'end': 'start'}}

                        type="email"
                      value={formData.email_address}
                      onChange={(e) =>
                        setFormData({ ...formData, email_address: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="form-input d-flex" style={{flexDirection: isRTL ?'row-reverse' :'row',padding:'5px'}}>
                    <span className="inputLabel">טלפון</span>
                    <input required
                        style={{textAlign:isRTL? 'end': 'start'}}

                        type="number"
                      value={formData.phone_number}
                      onChange={(e) =>
                        setFormData({ ...formData, phone_number: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="form-Textarea d-flex mt-2" style={{flexDirection: isRTL ?'row-reverse' :'row',padding:'5px'}}>
                    <span className="inputLabel">{t('Contact.Form.Message')}</span>
                    <textarea required
                        style={{textAlign:isRTL? 'end': 'start'}}
                      rows="5"
                      value={formData.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              <div style={{display:'flex',flexDirection: isRTL ? 'row-reverse' : 'row'}}>
              <button
                className="btn view-all-btn d-flex align-items-center
                 justify-content-center text-white my-5 "
                onClick={sendEmail}
              >
                {t('Contact.Form.Send')}
              </button>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-12 col-md-6 mb-4 contactDetailsCardContainer">
                  <div className="contactDetailsCard">
                    <div className="d-flex flex-column">
                      <span
                        className="iconify"
                        data-icon="carbon:phone-voice"
                      ></span>
                      <h6 className="darkPurpleHighlight">{t('Contact.Form.ContactNumber')}</h6>
                      <span>03-5185496</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4 contactDetailsCardContainer">
                  <div className="contactDetailsCard">
                    <div className="d-flex flex-column">
                      <span
                        className="iconify"
                        data-icon="clarity:map-marker-line"
                      ></span>
                      <h6 className="darkPurpleHighlight">{t('Contact.Form.Email')}</h6>
                      <span>{t('Contact.Form.WaterlessAddress')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4 contactDetailsCardContainer">
                  <div className="contactDetailsCard">
                    <div className="d-flex flex-column">
                      <span
                        className="iconify"
                        data-icon="ant-design:mail-outlined"
                      ></span>
                      <h6 className="darkPurpleHighlight">{t('Contact.Form.Email')}</h6>
                      <span>waterless.israel@gmail.com</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4 contactDetailsCardContainer">
                  <div className="contactDetailsCard">
                    <div className="d-flex flex-column">
                      <span
                        className="iconify"
                        data-icon="akar-icons:clock"
                      ></span>
                      <h6 className="darkPurpleHighlight">{t('Contact.Form.WorkingHours')}</h6>
                      <span>{t('Contact.Form.WorkingDays')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
