import React from "react";
import articles from "../../backend/db/articles.json";
import ArticleRouter from "./ArticleRouter";

const Articles = () => {
  return (
    <div className="container">
      <h1 className="text-center mt-5 mb-4" style={{  }}>
        מאמרים ויתרונות
      </h1>
      <div className="row">
        {articles.map((article) => (
          <div key={article.Id} className="col-md-4 mb-4">
            <ArticleRouter
              title={article.Title}
              route={article.Route}
              introduction={article.Introduction}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Articles;
