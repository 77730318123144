import React from 'react';
import EmailForm from '../../EmailForm/EmailForm';

const ContainerHomeForm = () => (
<div className="container-fluid px-3 mt-5 px-md-5">
<div className="contactContainer h-100 py-5">
  <div className="row pb-5" style={{ flexDirection:'row-reverse'}}>
    <div className="col-12 col-md-6 ps-md-5" style={{
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div className="contact-text" style={{textAlign: 'center'}}>
        <h2 className="text-white">
          התחבר אלינו ובואו ניקח את זה קדימה
        </h2>
        <p className="text-white mt-4" style={{textAlign: 'center'}}>
          המטרה העיקרית שלנו היא שיווק מוצרי קו ירוק והתרחבות
          עסקים לתחומים אחרים הקשורים לחיסכון במים או
          שיווק למוסדות.
        </p>
      </div>
    </div>
    <EmailForm/>
  </div>
</div>
</div>);
export default ContainerHomeForm;