import React, { useState } from "react";
import TagManager from "react-gtm-module";
import  emailjs  from '@emailjs/browser';

const EmailForm = () => {
  const [data, setData] = useState({
    from_name: "",
    email_address: "",
    phone_number: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!data.from_name.trim()) {
      errors.from_name = "שם פרטי הוא שדה חובה";
      isValid = false;
    }

    if (!data.email_address.trim()) {
      errors.email_address = "כתובת המייל היא שדה חובה";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email_address)) {
      errors.email_address = "כתובת המייל אינה תקינה";
      isValid = false;
    }

    if (!data.phone_number.trim()) {
      errors.phone_number = "מספר הטלפון הוא שדה חובה";
      isValid = false;
    }
    
    if (!data.message.trim()) {
      errors.message = "הודעה היא שדה חובה";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const Send = () => {
    emailjs
    .send("service_x8pw3kx", "template_jkunoy7", { ...data })
    .then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);

        TagManager.dataLayer({
          dataLayer: {
            event: "contact_us",
            pageTitle: document.title,
          },
        });

        setData({
          from_name: "",
          email_address: "",
          phone_number: "",
          message: "",
        });

        alert("ההודעה נשלחה בהצלחה!");
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
};

  const sendEmail = () => {
    debugger;
    if (validateForm()) {
      Send();
    }
  };

  const onDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="col-12 col-md-6">
      <div className="contact-form">
        <div
          style={{ flexDirection: "row-reverse", padding: "5px" }}
          className="form-input d-flex align-items-center ps-2"
        >
          <span
            className="iconify text-white"
            data-icon="charm:mail"
          ></span>
          <input
            value={data.from_name}
            style={{ textAlign: "end", margin: "10px" }}
            type="text"
            name={"from_name"}
            placeholder="שם פרטי"
            onChange={onDataChange}
          />
        </div>
        <div className="text-center"> {/* Center the error message */}
          {errors.from_name && (
            <div className="text-danger error-message">{errors.from_name}</div>
          )}
        </div>
        <div
          style={{ flexDirection: "row-reverse", padding: "5px" }}
          className="form-input d-flex align-items-center ps-2"
        >
          <span
            className="iconify text-white"
            data-icon="charm:mail"
          ></span>
          <input
            value={data.email_address}
            style={{ textAlign: "end", margin: "10px" }}
            type="email"
            name={"email_address"}
            placeholder="אימייל"
            onChange={onDataChange}
          />
        </div>
        <div className="text-center"> {/* Center the error message */}
          {errors.email_address && (
            <div className="text-danger error-message">{errors.email_address}</div>
          )}
        </div>
        <div
          style={{ flexDirection: "row-reverse", padding: "5px" }}
          className="form-input d-flex align-items-center ps-2"
        >
          <span
            className="iconify text-white"
            data-icon="carbon:phone"
          ></span>
          <input
            value={data.phone_number}
            style={{ textAlign: "end", margin: "10px" }}
            type="text"
            name={"phone_number"}
            onChange={onDataChange}
            placeholder="טלפון ליצירת קשר"
          />
        </div>
        <div className="text-center"> {/* Center the error message */}
          {errors.phone_number && (
            <div className="text-danger error-message">{errors.phone_number}</div>
          )}
        </div>
        <div className="form-textarea">
          <textarea
            value={data.message}
            style={{ textAlign: "end", padding: "5px" }}
            name={"message"}
            onChange={onDataChange}
            cols="30"
            rows="5"
            className="ps-2"
            placeholder="ההודעה שלך"
          ></textarea>
        </div>
        <div className="text-center"> {/* Center the error message */}
          {errors.message && (
            <div className="text-danger error-message">{errors.message}</div>
          )}
        </div>
        <button
          className="btn btn-submit text-white"
          style={{ margin: "auto" }}
          onClick={sendEmail}
        >
          שלח
        </button>
      </div>
    </div>
  );
};

export default EmailForm;