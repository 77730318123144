import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const recommendations = [
  {
    name: 'אלי',
    job: 'מנהל לוגיסטיקה של ביטוח לאומי',
    text:
      'בהחלט אמליץ וממליץ לכל מי ששואל אותי, מאוד מרוצה מהשירות ומהשימוש במשתנים של Waterless.',
  },
  {
    name: 'מיה',
    job: 'מנהלת עסק קטן',
    text:
    'המוצרים של Waterless הם פתרון מבריק לחיסכון במים ולתחזוקה פשוטה ויעילה. אני ממליצה בחום על מוצריהם האיכותיים והחסכוניים.',
    },
    {
      name: 'אורן',
      job: 'אדריכל',
      text:
      'השימוש במוצרים של Waterless מעניק לי גמישות משמעותית בתכנון השירותים היגייניים במבנים שאני מעצב. אני ממליץ בחום על החדשנות והאיכות שהם מציעים.',
      },
];

const calcRecommendation = (name, job, text) => (
  <div>
    <img src="./assets/images/individual.png" className="client-img" />
    <div className="myCarousel">
      <h3 className="text-center">{name}</h3>
      <h4>{job}</h4>
      <p className="recommendation-text text-center">"{text}"</p>
    </div>
  </div>
);

const CustomerReviews = () => (
  <div className="lightGreenBg container-fluid px-3 px-md-5 py-5">
    <h2 className="text-center mb-5">מה הלקוחות שלנו אומרים עלינו</h2>
    <div className="customerReviewContainer">
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        {recommendations.map((rec) =>
          calcRecommendation(rec.name, rec.job, rec.text)
        )}
      </Carousel>
    </div>
  </div>
);

export default CustomerReviews;
