import "./App.css";
import React from 'react';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import emailjs from '@emailjs/browser';
import Router from "./components/router";
import {BrowserRouter} from "react-router-dom";
const Header = React.lazy(() => import('./components/Header'));
const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  emailjs.init("9nbnGNk7XHa_YLhSm");

  return (
    <div className="App">
        <BrowserRouter>
        <Header/>
      <Router/>
      <WhatsAppWidget
          phoneNo="+972525970820"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={false}
          autoOpenTimer={10000}
          messageBox={true}
          messageBoxTxt="היי מעוניין לשמוע פרטים נוספים"
          iconSize="70"
          iconColor="white"
          iconBgColor="#00cda6"
          //  headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
          headerIconColor="pink"
          headerTxtColor="black"
          headerBgColor="#94ECBD"
          headerTitle="מוטי"
          headerCaption="מחובר"
          bodyBgColor="#FAFAFA"
          chatPersonName="שירות ותמיכה"
          chatMessage={<>👋 שלום <br /><br /> איך אוכל לעזור?</>}
          footerBgColor="#999"
          btnBgColor="#94ECBD"
          btnTxtColor="black"
          btnTxt="התחל שיחה"
      />
      <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
